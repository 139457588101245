'use strict';

/*******************************************************************************************/
class UxrEeFormatted extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = '';
		this.state = {
			'value': this.props.value || this.props.defaultValue || this.defaultValue,
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
  
/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
 
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
		let quillOpts = _.defaults(
			{}, 
			this.props.quill,
			{
				/*
				//default is all
				formats: [
					'header', 'font', 'size',
					'bold', 'italic', 'underline', 'strike', 'blockquote',
					'list', 'bullet', 'indent',
					'link', 'image', 'video',
				],
				*/
				modules: {
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],        // toggled buttons
						['link', 'image', 'video'],
						['blockquote', 'code-block'],
						[{ 'header': 1 }, { 'header': 2 }],               // custom button values
						[{ 'list': 'ordered'}, { 'list': 'bullet' }],
						[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
						[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
						[{ 'direction': 'rtl' }],                         // text direction
						[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
						[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
						[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
						[{ 'font': [] }],
						[{ 'align': [] }],
						['clean']                                         // remove formatting button
					],
				},
			}
		);
		
		return (
			<div {...this.props.htmlProps} >
			<UxrEe_.Quill {...quillOpts} placeholder={this.props.placeholder} value={this.state.value} onChange={this.onChange} theme="snow" />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(value) {
		//this.setState({
		//	'value': value
		//})
		this.props.onChange(this, {value});
	}
	
} //class
